import { useState, useRef, useEffect } from "react";
import postImage from "../../assets/images/placeholder/post-placeholder.jpg";

const ExtrasList = ({ header, posts }) => {
  const [lists, setLists] = useState(posts);
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });
  return (
    <>
      <div className="row justify-content-center mb-2 px-0">
        <div className="col " ref={sectionBorderRef}>
          <label className="th-fs-3 m-0 fw-semibold" ref={sectionTitleRef}>
            {header}
          </label>
        </div>
      </div>
      <div className="row mx-0 mb-1">
        <div className="col-12">
          <ul className="list-group">
            {lists.map((list) => (
              <li
                key={list.id}
                className="list-group-item border-0 d-flex justify-content-start gap-2"
              >
                <smalll>{list.itemName}</smalll>
                <strong>{list.number}</strong>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ExtrasList;
