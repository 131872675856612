import axios from "axios";
import { data } from "jquery";
import alertify from "alertifyjs";
import React, { useState, useEffect } from "react";
import Header from "../shared/Header";
import { auth, firestore } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  updatePhoneNumber,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

const StepForm = () => {
  alertify.set("notifier", "position", "top-center");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  //   sets the display of either first or second part of Registration Form
  const [active, setActive] = useState({
    id: "firstContent",
  });

  const postBtn = "register";

  const toggleDisplay = (e) => {
    const elementsWithInvalidClass = document.querySelectorAll("is-invalid");
    if (
      elementsWithInvalidClass.length === 0 &&
      firstName !== "" &&
      middleName !== "" &&
      lastName !== ""
    ) {
      setActive({
        id: e.target.id,
      });
    } else {
      alertify.error("Some Data Are Missing!.. Fill up...");
    }
  };
  const fields = [
    {
      id: 1,
      fieldType: "email",
      fieldName: "email",
      fieldPlaceholder: "Email",
      step: 1,
    },
    {
      id: 2,
      fieldType: "text",
      fieldName: "username",
      fieldPlaceholder: "Username",
      step: 1,
    },
    {
      id: 3,
      fieldType: "password",
      fieldName: "password",
      fieldPlaceholder: "Password",
      step: 1,
    },

    {
      id: 5,
      fieldType: "text",
      fieldName: "firstname",
      fieldPlaceholder: "First Name",
      step: 2,
    },

    {
      id: 6,
      fieldType: "text",
      fieldName: "middlename",
      fieldPlaceholder: "Middle Name",
      step: 2,
    },
    {
      id: 7,
      fieldType: "text",
      fieldName: "lastname",
      fieldPlaceholder: "Last Name",
      step: 2,
    },
    {
      id: 8,
      fieldType: "text",
      fieldName: "address",
      fieldPlaceholder: "Address",
      step: 2,
    },
    {
      id: 9,
      fieldType: "text",
      fieldName: "contactnumber",
      fieldPlaceholder: "Contact Number",
      step: 2,
    },
  ];

  const postDataHandler = async (e) => {
    e.preventDefault();
    const elementsWithInvalidClass = document.querySelectorAll(".is-invalid");
    if (elementsWithInvalidClass.length === 0) {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      updateProfile(auth.currentUser, {
        displayName: firstName + " " + middleName + " " + lastName,
        photoURL: null,
      });
      //updatePhoneNumber(auth.currentUser,contactNumber);
      //adding data to firestore
      try {
        var userData = doc(firestore, "user", userCredentials.user.uid);
        const userDataSet = await setDoc(userData, {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email: email,
          username: username,
          contact_number: contactNumber,
          address: address,
          user_type: "user",
        })
          .then(() => {
            if (userCredentials !== null) {
              //userDataFunc(userCredentials.user.uid);
              alertify.success("Account Creation Successful");
              window.location.href = window.location.origin + "/login";
            } else {
              window.location.href = window.location.origin + "/register";
            }
          })
          .catch((error) => {
            alertify.error("Data Registering Error!..");
            window.location.href = window.location.origin + "/register";
          });
      } catch (e) {
        alertify.error("Data Registering Error!..");
      }
    } else {
      alertify.error("Some Data Are Missing!.. Fill up...");
    }
  };
  /*
  async function userDataFunc(uid)
  {
    if (uid !== null) {
      const docUser = doc(firestore, "user", uid);
      const docSnapUser = await getDoc(docUser);

      if (docSnapUser.exists()) {
        //console.log("Document data:", docSnapUser.data());
        const userData = docSnapUser.data();
        localStorage.setItem(
          "logged_address",
          userData.address
        );
        localStorage.setItem(
          "logged_user",
          userData.username
        );
        localStorage.setItem(
          "logged_user_type",
          userData.user_type
        );
        localStorage.setItem(
          "logged_phoneNumber",
          userData.contact_number
        );
        localStorage.setItem(
          "logged_fullname",
          userData.first_name + " " + userData.middle_name + " " + userData.last_name,
        );
              
        localStorage.setItem(
          "logged_email",
          userData.email
        );
      }
    }
  }
  */
  function emptyFields(id, value, input_id) {
    var usernamePattern = new RegExp("^[a-zA-Z0-9_]{6,}$");
    const nameRegex = /^[a-zA-Z\s]{2,30}$/;
    const phoneNumberRegex = /^\d{11}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (input_id === "firstName") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "middleName") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "lastname") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "contactNumber") {
      id.classList.toggle("is-invalid", phoneNumberRegex.test(value) === false);
      id.classList.toggle("is-valid", phoneNumberRegex.test(value) === true);
    } else if (input_id === "password") {
      id.classList.toggle("is-invalid", passwordRegex.test(value) === false);
      id.classList.toggle("is-valid", passwordRegex.test(value) === true);
    } else if (input_id === "username") {
      id.classList.toggle("is-invalid", usernamePattern.test(value) === false);
      id.classList.toggle("is-valid", usernamePattern.test(value) === true);
    } else if (input_id === "email") {
      id.classList.toggle("is-invalid", emailPattern.test(value) === false);
      id.classList.toggle("is-valid", emailPattern.test(value) === true);
    } else {
      id.classList.toggle("is-invalid", value === "");
      id.classList.toggle("is-valid", value !== "");
    }
  }

  return (
    <form
      className="needs-validation"
      novalidate
      onSubmit={(e) => postDataHandler(e)}
    >
      {/* First Content */}
      <div
        className={
          active.id === "firstContent"
            ? "firstContent row gap-2 m-0 my-3"
            : "firstContent row gap-2 m-0 my-3 d-none"
        }
      >
        <div className=" custom-field col-12 col-lg-12 mb-3">
          <label className="form-label">First Name</label>
          <input
            required
            type="text"
            name="firstname"
            id="firstName"
            className="form-control w-100"
            onChange={(e) => {
              setFirstName(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={firstName}
          />
        </div>
        <div className=" custom-field col-12 col-lg-12 mb-3">
          <label className="form-label">Middle Name</label>
          <input
            required
            type="text"
            name="middlename"
            id="middleName"
            className="form-control w-100"
            onChange={(e) => {
              setMiddleName(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={middleName}
          />
        </div>
        <div className=" custom-field col-12 col-lg-12 mb-3">
          <label className="form-label">Last Name</label>
          <input
            required
            type="text"
            name="lastname"
            id="lastname"
            className="form-control w-100"
            onChange={(e) => {
              setLastName(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={lastName}
          />
        </div>

        <button
          type="button"
          className="btn th-btn-primary text-uppercase mt-3 mb-2"
          id="secondContent"
          onClick={(e) => {
            toggleDisplay(e);
          }}
        >
          next
        </button>
      </div>
      {/* Second Content */}
      <div
        className={
          active.id === "secondContent"
            ? `secondContent row gap-2 m-0 my-3`
            : "secondContent row gap-2 m-0 my-3 d-none"
        }
      >
        <div className=" custom-field col-12 col-lg-12 mb-1">
          <label className="form-label">Username</label>
          <input
            required
            type="username"
            name="username"
            id="username"
            className="form-control w-100"
            onChange={(e) => {
              setUsername(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={username}
          />
        </div>
        <div className=" custom-field col-12 col-lg-12 mb-1">
          <label className="form-label">Password</label>
          <input
            required
            type="password"
            name="password"
            id="password"
            className="form-control w-100"
            onChange={(e) => {
              setPassword(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={password}
          />
        </div>
        <div className=" custom-field col-12 col-lg-12 mb-1">
          <label className="form-label">E-mail</label>
          <input
            required
            type="email"
            name="email"
            id="email"
            className="form-control w-100"
            onChange={(e) => {
              setEmail(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={email}
          />
        </div>

        <div className=" custom-field col-12 col-lg-12 mb-1">
          <label className="form-label">Contact Number</label>
          <input
            required
            type="number"
            name="contactno"
            id="contactNumber"
            className="form-control w-100"
            onChange={(e) => {
              setContactNumber(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            value={contactNumber}
          />
        </div>
        <div className=" custom-field col-12 col-lg-12 mb-1">
          <label className="form-label">Address</label>
          <textarea
            name="address"
            id="address"
            className="form-control w-100"
            onChange={(e) => {
              setAddress(e.target.value);
              emptyFields(e.target, e.target.value, e.target.id);
            }}
            required
            value={address}
          />
        </div>

        <div className="row m-0 gy-1 mt-2 p-0">
          <button
            className="btn btn-light border text-uppercase mb-2"
            id="firstContent"
            onClick={(e) => {
              toggleDisplay(e);
              emptyFields(e.target, e.target.value);
            }}
          >
            back
          </button>
          <button className="btn th-btn-primary text-uppercase mb-2">
            {postBtn}
          </button>
        </div>
      </div>
    </form>
  );
};
export default StepForm;
