import { useRef, useEffect, useState } from "react";
import Footer from "../../components/shared/Footer";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/images/placeholder/banner-placeholder.jpg";
import poster from "../../assets/images/proj/p9.jpg";
import img1 from "../../assets/images/proj/bagyo.jpg";
import img2 from "../../assets/images/proj/lindol.jpg";
import img3 from "../../assets/images/proj/baha.jpg";
import { auth, firestore } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
const Preparation = () => {
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const altLabelImage = "banner-placeholder";
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid #242424";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });
  const image = {
      height: "80vh",
    },
    cardimage = {
      height: "150px",
      width: "100%",
      objectFit: "cover",
    },
    customStyle = {
      marginTop: "100px",
    };

  const [checklist, setChecklist] = useState([]);
  const [ID, setID] = useState("");
  const [percentage, setPercentage] = useState("");
  const [downloadLink, setDownloadLink] = useState("");

  const fetchDownloadLink = async () => {
    try {
      const downloadLinkSnapshot = await getDocs(
        collection(firestore, "DownloadLinks")
      );

      let link = "";
      downloadLinkSnapshot.forEach((doc) => {
        link = doc.data().url;
      });

      setDownloadLink(link);
    } catch (error) {
      console.error("Error fetching download link: ", error);
      // Handle errors if necessary
    }
  };

  // Fetch download link when the component mounts
  useEffect(() => {
    fetchDownloadLink();
  }, []);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setID(user.uid);
      } else {
        // handle signed out state if needed
      }
    });

    return () => {
      // Unsubscribe the observer when the component unmounts
      unsubscribe();
    };
  }, [ID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDocs(
          query(
            collection(firestore, "Preparation_Checklist"),
            where("uid", "==", ID)
          )
        );
        let checklistPop = [];
        data.forEach((doc) => {
          const documents = doc.data();
          let disasterType, imageName, percentage;
          let checklistItem = [];
          if (documents.category === "EARTHQUAKE") {
            disasterType = "Earthquake";
            percentage = documents.percentage;
            imageName = img2;
            checklistItem = [
              {
                id: 1,
                name: documents.checkboxValue1 || "First aid kit",
                value: documents.checkbox1 || false,
              },
              {
                id: 2,
                name: documents.checkboxValue2 || "Flashlight",
                value: documents.checkbox2 || false,
              },
              {
                id: 3,
                name: documents.checkboxValue3 || "Water",
                value: documents.checkbox3 || false,
              },
              {
                id: 4,
                name: documents.checkboxValue4 || "Battery",
                value: documents.checkbox4 || false,
              },
              {
                id: 5,
                name: documents.checkboxValue5 || "Blanket",
                value: documents.checkbox5 || false,
              },
              {
                id: 6,
                name: documents.checkboxValue6 || "Paracetamol",
                value: documents.checkbox6 || false,
              },
              {
                id: 7,
                name: documents.checkboxValue7 || "Hygien Kit",
                value: documents.checkbox7 || false,
              },
              {
                id: 8,
                name: documents.checkboxValue8 || "Candles & Matches",
                value: documents.checkbox8 || false,
              },
              {
                id: 9,
                name: documents.checkboxValue9 || "Can goods(Good for 3 days)",
                value: documents.checkbox9 || false,
              },
              {
                id: 10,
                name: documents.checkboxValue10 || "Water(Good for 3 days)",
                value: documents.checkbox10 || false,
              },
            ];
          } else if (documents.category === "TYPHOON") {
            disasterType = "Typhoon";
            imageName = img1;
            percentage = documents.percentage;
            checklistItem = [
              {
                id: 1,
                name: documents.checkboxValue1 || "First aid kit",
                value: documents.checkbox1 || false,
              },
              {
                id: 2,
                name: documents.checkboxValue2 || "Flashlight",
                value: documents.checkbox2 || false,
              },
              {
                id: 3,
                name: documents.checkboxValue3 || "Water",
                value: documents.checkbox3 || false,
              },
              {
                id: 4,
                name: documents.checkboxValue4 || "Battery",
                value: documents.checkbox4 || false,
              },
              {
                id: 5,
                name: documents.checkboxValue5 || "Blanket",
                value: documents.checkbox5 || false,
              },
              {
                id: 6,
                name: documents.checkboxValue6 || "Paracetamol",
                value: documents.checkbox6 || false,
              },
              {
                id: 7,
                name: documents.checkboxValue7 || "Hygien Kit",
                value: documents.checkbox7 || false,
              },
              {
                id: 8,
                name: documents.checkboxValue8 || "Candles & Matches",
                value: documents.checkbox8 || false,
              },
              {
                id: 9,
                name: documents.checkboxValue9 || "Can goods(Good for 3 days)",
                value: documents.checkbox9 || false,
              },
              {
                id: 10,
                name: documents.checkboxValue10 || "Water(Good for 3 days)",
                value: documents.checkbox10 || false,
              },
            ];
          } else {
            disasterType = "Flash Flood";
            imageName = img3;
            percentage = documents.percentage;
            checklistItem = [
              {
                id: 1,
                name: documents.checkboxValue1 || "First aid kit",
                value: documents.checkbox1 || false,
              },
              {
                id: 2,
                name: documents.checkboxValue2 || "Flashlight",
                value: documents.checkbox2 || false,
              },
              {
                id: 3,
                name: documents.checkboxValue3 || "Water",
                value: documents.checkbox3 || false,
              },
              {
                id: 4,
                name: documents.checkboxValue4 || "Battery",
                value: documents.checkbox4 || false,
              },
              {
                id: 5,
                name: documents.checkboxValue5 || "Blanket",
                value: documents.checkbox5 || false,
              },
              {
                id: 6,
                name: documents.checkboxValue6 || "Paracetamol",
                value: documents.checkbox6 || false,
              },
              {
                id: 7,
                name: documents.checkboxValue7 || "Hygien Kit",
                value: documents.checkbox7 || false,
              },
              {
                id: 8,
                name: documents.checkboxValue8 || "Candles & Matches",
                value: documents.checkbox8 || false,
              },
              {
                id: 9,
                name: documents.checkboxValue9 || "Can goods(Good for 3 days)",
                value: documents.checkbox9 || false,
              },
              {
                id: 10,
                name: documents.checkboxValue10 || "Water(Good for 3 days)",
                value: documents.checkbox10 || false,
              },
            ];
          }

          const checklistObject = {
            id:
              documents.category === "EARTHQUAKE"
                ? 2
                : documents.category === "TYPHOON"
                ? 1
                : 3,
            disaster: disasterType,
            imageName: imageName,
            description:
              documents.category === "TYPHOON"
                ? "Heavy rain and strong gust of wind are your enemies during this time. Going outside is not an option so you better be prepared"
                : documents.category === "EARTHQUAKE"
                ? "A silent killer as they say, earthquakes will not show any signs until it is ready to strike. Better have these tools and necessities ready."
                : "Heavy rains often cause you unable to go outside but never disregard the fact that flashfloods are sometimes an effect especially if the area has  unfunctional sewage system. Prepare for the storm as well as its aftermath.", // Add your description logic here
            checklistItem: checklistItem,
            percentage: percentage,
          };

          checklistPop.push(checklistObject);
        });

        setChecklist(checklistPop);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [ID]);
  const [modalId, setModalId] = useState("");
  const setModalValues = (id, percent) => {
    const selectedDisaster = checklist.find((item) => item.id === id);
    if (selectedDisaster) {
      setModalId(id);
      setPercentage(percent);

      // Set values inside modal
      document.getElementById("disaster").innerHTML =
        " " + selectedDisaster.disaster;
      const checklistItemsContainer = document.getElementById("checklistItem");
      checklistItemsContainer.innerHTML = "";

      const listGroup = document.createElement("ul");
      listGroup.className = "list-group text-start list-group-flush";
      selectedDisaster.checklistItem.forEach((listItem) => {
        const listItemElement = document.createElement("li");
        listItemElement.className = "list-group-item border-0";
        const checkboxInput = document.createElement("input");
        checkboxInput.className = "form-check-input me-1";

        checkboxInput.type = "checkbox";
        checkboxInput.checked = listItem.value;
        checkboxInput.disabled = true;
        checkboxInput.id = `checkbox_${listItem.id}`;
        checkboxInput.key = `checkbox_${listItem.id}`;
        const label = document.createElement("label");
        label.className = "form-check-label";
        label.htmlFor = `checkbox_${listItem.id}`;
        label.textContent = listItem.name;
        listItemElement.appendChild(checkboxInput);
        listItemElement.appendChild(label);
        listGroup.appendChild(listItemElement);
      });
      checklistItemsContainer.appendChild(listGroup);
    } else {
      // Handle error: selected disaster not found
    }
  };

  return (
    <>
      <div className="container py-4" style={customStyle}>
        <div className="row m-0">
          {/* Section Title */}
          <div className="row justify-content-start mb-3 ">
            <div className="col-12 lh-sm" ref={sectionBorderRef}>
              <label className="th-fs-1 " ref={sectionTitleRef}>
                Disaster Preparation Checklist
              </label>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12 row gap-2">
            <div className="row gap-2 m-0 p-0">
              {/* Cards */}
              <div className="col-lg col-12">
                <div className="row gap-3 mx-0">
                  {checklist.length > 0 ? (
                    checklist.map((list) => {
                      return (
                        <div className="col-lg-5 col-12 m-0 p-0">
                          <button
                            className="btn btn-light card m-0 p-0 text-start text-decoration-none "
                            modal-id={list.id}
                            onClick={() =>
                              setModalValues(list.id, list.percentage)
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#modal"
                          >
                            <img
                              src={list.imageName}
                              className="card-img-top img-fluid"
                              alt="..."
                              style={cardimage}
                            />
                            <div className="card-body">
                              <h5 className="card-title th-fw-semibold">
                                {list.disaster}
                              </h5>
                              <p className="card-text pe-auto">
                                {list.description}
                              </p>
                              <p className="card-text ">
                                <small className="text-secondary">
                                  {list.createdAt}
                                </small>
                              </p>
                            </div>
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12 text-center mt-4">
                      <p className="fs-2 fw-bold lh-base">
                        You don't have a disaster preparation checklist yet. You
                        can update your disaster preparation checklist through
                        our mobile app.{" "}
                      </p>
                      <p className="fw-bold fs-3 mt-5">Download Here</p>
                      <a
                        href={downloadLink} // Replace this with the URL you want to link to
                        target="_blank" // Opens the link in a new tab/window
                        rel="noopener noreferrer" // Recommended for security reasons
                        className="btn btn-primary btn-lg"
                      >
                        Download
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md col-12 p-0">
            <img src={poster} className="img-fluid w-100" style={image} />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "100000" }}
      >
        <div className="modal-dialog modal-dialog-centered  modal-dialog-scrollable">
          <div className="modal-content overflow-auto">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Preparations Plan:
                <strong id="disaster" className=" fs-5 th-fw-bold"></strong>
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="col d-flex justify-content-center ">
                <label className=" th-fs-1">
                  You're{" "}
                  <label className="th-fs-1 th-fw-semibold px-1">
                    {percentage}%
                  </label>
                  prepared
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <div className="col-8">
                  <div id="checklistItem" className="row gap-2 mx-0"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      );
    </>
  );
};
export default Preparation;
