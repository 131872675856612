// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth} from "firebase/auth";
import { getFirestore} from "firebase/firestore";
import { getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrjGoGR40lCc0nF6DL1n1YmUz_etuv6Tw",
  authDomain: "ehanda-cfc3f.firebaseapp.com",
  projectId: "ehanda-cfc3f",
  storageBucket: "ehanda-cfc3f.appspot.com",
  messagingSenderId: "676970384481",
  appId: "1:676970384481:web:f39f136363916bb076ae20",
  measurementId: "G-1YNXEE631P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export {auth, firestore, storage};
export default app;