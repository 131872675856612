import React, { useState } from "react";
import PostList from "../../components/lists/PostList";
import FeaturedList from "../../components/lists/FeaturedList";
import CardList from "../../components/lists/CardList";
import AvatarList from "../../components/lists/AvatarList";
import bannerImage from "../../assets/images/placeholder/municipal.png";
import RecommendationList from "../../components/lists/RecommendationList";
import ExtrasList from "../../components/lists/ExtrasList";

import annImg1 from "../../assets/images/proj/lindol.jpg";
import annImg2 from "../../assets/images/proj/bagyo.jpg";
import annImg3 from "../../assets/images/proj/sunog.jpg";
import annImg4 from "../../assets/images/proj/baha.jpg";

import orgImg1 from "../../assets/images/icon/bfp.png";
import orgImg2 from "../../assets/images/icon/pnp.png";
import orgImg3 from "../../assets/images/icon/rhu.png";
import orgImg4 from "../../assets/images/icon/mdrrmo.png";

import recImg1 from "../../assets/images/proj/p5.jpg";
import recImg2 from "../../assets/images/proj/p7.jpg";
import recImg3 from "../../assets/images/proj/volcanicEruption.jpg"; 
const Home = () => {
  const altLabelImage = "banner-placeholder";

  const sectionTitle = [
    "Events",
    "Announcement",
    "Preparation Plans",
    "Disaster Relief and Assistance",
    "Emergency Hotlines",
    "Recommendations",
  ];
  const announcements = [
    {
      postId: 1,
      imageName: annImg1,
      altImageLabel: "",
      title: "Powerful earthquake hits northern Philippines",
      body: "MANILA: A 7.0-magnitude earthquake hit the northern Philippines Wednesday, authorities said, killing one person, shattering windows at the epicentre and shaking high-rise towers more than 300 kilometres (185 miles) away in the capital Manila.The shallow but powerful quake struck the mountainous and lightly populated province of Abra on the main island of Luzon at 8:43 am (0043 GMT), the US Geological Survey said.",
    },
    {
      postId: 2,
      imageName: annImg2,
      altImageLabel: "",
      title: "13 Killed, 23 Missing In Philippines Floods: Report",
      body: "MANILA: Heavy rains and flash floods over Christmas claimed 13 lives in Philippines, with a further 23 missing who are believed to be fishermen, Al Jazeera reported on Tuesday citing the country's disaster management council. The calamity not only wreaked havoc in the country but also pushed more than 45,000 people into evacuation centers for shelter. The fishermen who are reportedly missing went out to sea despite the dangers associated with bad weather, the disaster agency said.",
    },
    {
      postId: 3,
      imageName: annImg3,
      altImageLabel: "",
      title:
        "Increasing number of houses burning, accumululated 300 families are affected within an hour",
      body: "Quezon City: Around 300 families affected after a fire gutted their community in Tandang Sora, Quezon City, Reaching the first alarm at 3:45 AM. Local Authorities have prepared a rescue operations and provide an evacuations.",
    },
  ];
  const preps = [
    {
      prepsId: 1,
      imageName: annImg2,
      altImageLabel: "",
      title: "Typhoon",
    },
    {
      prepsId: 2,
      imageName: annImg4,
      altImageLabel: "",
      title: "Flood",
    },
    {
      prepsId: 3,
      imageName: annImg1,
      altImageLabel: "",
      title: "Earthquake",
    },
  ];

  const organizations = [
    {
      orgId: 1,
      imageName: orgImg1,
      altImageLabel: "",
      title: "Sto Domingo Fire Station",
      hotline: "(02) 8426-0219, (02) 8426-0246",
      contact: "0923-163-3087, 0977-503-9274",
      facebook: "@BFP",
      twitter: "@BFP_Office",
    },
    {
      orgId: 2,
      imageName: orgImg2,
      altImageLabel: "",
      title: "Sto Domingo Police Station",
      hotline: "",
      contact: "0919-264-1213, 0977-854-1207",
      facebook: "@PNP",
      twitter: "@PNP_Office",
    },
    {
      orgId: 3,
      imageName: orgImg3,
      altImageLabel: "",
      title: "Rural Health Unit",
      hotline: "",
      contact: "0926-806-3536, 0917-456-7649",
      facebook: "@rhu",
      twitter: "@rhu_office",
    },
    {
      orgId: 4,
      imageName: orgImg4,
      altImageLabel: "",
      title: "MDRRMO Office",
      hotline: "",
      contact: "0967-271-1143, 0942-253-7303",
      facebook: "@MDDRMO",
      twitter: "@MDRRMO_Office",
    },
  ];

  const recommendations = [
    {
      recId: 1,
      imageName: recImg1,
      altImageLabel: "",
      category: "Events",
      title: "Bureau of Fire Protection: Drill Event",
      date_created: "May 7 2023",
    },
    {
      recId: 2,
      imageName: recImg3,
      altImageLabel: "",
      category: "Nature",
      title: "Preparedness for possible Volcano Eruptions",
      date_created: "April 27 2023",
    },
    {
      recId: 3,
      imageName: recImg2,
      altImageLabel: "",
      category: "Operations",
      title: "Rescued civilian to a burning establishment",
      date_created: "April 27 2023",
    }
  ];

  const hotlines = [
    {
      id: 1,
      itemName: "Rural Health Unit",
      number: "0926-806-3536",
    },
    {
      id: 2,
      itemName: "Police Station",
      number: "0919-264-1213",
    },
    {
      id: 3,
      itemName: "MDRRMO Office",
      number: "0967-271-1143",
    },
  ];
  const customStyle = {
    marginTop: "6.5rem",
  };
  return (
    <div className="container-fluid">
      <div className="row mx-0">
        <div className="col" style={customStyle}>
          <div className="row justify-content-center py-5 ">
            <div className="col px-4">
              <img
                src={bannerImage}
                alt={altLabelImage}
                className="bg-secondary img img-fluid rounded w-100"
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div className="col-10">
              <PostList
                header={sectionTitle.find(
                  (element) => element === "Announcement"
                )}
                posts={announcements}
              />

              <FeaturedList
                header={sectionTitle.find((element) => element === "Events")}
              />
              <CardList
                header={sectionTitle.find(
                  (element) => element === "Preparation Plans"
                )}
                posts={preps}
              />

              <AvatarList
                header={sectionTitle.find(
                  (element) => element === "Disaster Relief and Assistance"
                )}
                posts={organizations}
              />
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 d-lg-block d-none border-start mx-0 p-0 px-2"
          style={{ marginTop: "6.5rem" }}
        >
          <div
            className="position-fixed m-0 p-0"
            style={{ height: "85vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <ExtrasList
              header={sectionTitle.find(
                (element) => element === "Emergency Hotlines"
              )}
              posts={hotlines}
            />
            <RecommendationList
              header={sectionTitle.find(
                (element) => element === "Recommendations"
              )}
              posts={recommendations}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
