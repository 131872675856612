import React, { useEffect, useState } from 'react';
import { firestore } from "../../firebase"
import { doc, setDoc, addDoc, updateDoc, getDoc, deleteDoc, collection } from "firebase/firestore"
import alertify from "alertifyjs"
import 'alertifyjs/build/css/alertify.min.css';

const PriorityCase = ({ showModal, onClose ,caseData}) => {
    alertify.set('notifier', 'position', 'top-center');
    const { docID, name, category, status, comments } = caseData|| {};

    const [stateName, setStateName] = useState('');
    const [stateCategory, setStateCategory] = useState('');
    const [stateStatus, setStateStatus] = useState('');
    const [stateComments, setStateComments] = useState('');
    const [stateDocID, setStatedocID] = useState('');
    
    useEffect(() => {
      // Update state variables only once after the initial render
      if (localStorage.getItem("Priority_Value") === "Edit" || localStorage.getItem("Priority_Value") === "Delete") {
        setStatedocID(docID);
        setStateName(name);
        setStateCategory(category);
        setStateStatus(status);
        setStateComments(comments);
      }
    }, [docID, name, category, status, comments]);
  
  const handleNameChange = (e) => {
    setStateName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setStateCategory(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStateStatus(e.target.value);
  };
  const handleCommentsChange = (e) => {
    setStateComments(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const priorityCasesCollection = collection(firestore, "Priority_Cases");
    const docRef = await addDoc(priorityCasesCollection, {
      name: stateName,
      category: stateCategory,
      status: stateStatus,
      comments : stateComments,
    }).then(()=> {
          alertify.success('Data Added');
        }).catch((error) => {
          alertify.error('Data Error');
        })
    onClose();
  };
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    const updateCases = doc(firestore, "Priority_Cases", docID);

    await updateDoc(updateCases, {
      name: stateName,
      category: stateCategory,
      status: stateStatus,
      comments : stateComments,
  });
    onClose();
  };
  const handleSubmitDelete = (e) => {
    e.preventDefault();
    alertify.confirm("This is a confirm dialog.",
      async function () {
        const deleteRef = doc(firestore, "Priority_Cases", docID);
        try {
          await deleteDoc(deleteRef);
          alertify.success('Data Deleted');
          onClose();
        } catch (error) {
          console.log(error);
          alertify.error('Error Deleting');
        }
  },
  function(){
    onClose();
      }
  );
    };
  

  return (
    <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
          {localStorage.getItem("Priority_Value") === "Add" ? (<h5 className="modal-title">Add Priority Case</h5>)
                : localStorage.getItem("Priority_Value") === "Edit" ?( <h5 className="modal-title">Edit Priority Case</h5>)
                : (<h5 className="modal-title">Delete Priority Case</h5>)

                }
            
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
             <form onSubmit={
                localStorage.getItem("Priority_Value") === "Add" ? handleSubmit
                : localStorage.getItem("Priority_Value") === "Edit" ? handleSubmitEdit
                :null
              }>
              {localStorage.getItem("Priority_Value") === "Edit" || localStorage.getItem("Priority_Value") === "Delete" ? (
                <input
                  type="hidden"                  
                  id="docID"                  
                  value={stateDocID}                  
                  required                  
                />                
              ) : null}
              
              <div className="custom-field col-12 col-lg-12 mb-1">
                <label htmlFor="name" className='form-label'>Name</label>
                <input
                  type="text"
                  className="form-control w-100 mb-3"
                  id="email"
                  value={stateName}
                  onChange={handleNameChange}
                  required
                />
                </div>
                <div className="custom-field col-12 col-lg-12 mb-1">
                <label htmlFor="category" className='form-label'>Category</label>
                <input
                  type="text"
                  className="form-control w-100 mb-3"
                  id="category"
                  value={stateCategory}
                  onChange={handleCategoryChange}
                  required
                />
                </div>
                <div className="custom-field col-12 col-lg-12 mb-1">
                <label htmlFor="status" className='form-label'>Status</label>
                <input
                  type="text"
                  className="form-control w-100 mb-3"
                  id="status"
                  value={stateStatus}
                  onChange={handleStatusChange}
                  required
                />
              </div>
              <div className="custom-field col-12 col-lg-12 mb-1">
                <label htmlFor="comments" className='form-label'>Comments</label>
                <input
                  type="text"
                  className="form-control w-100 mb-3"
                  id="commensts"
                  value={stateComments}
                  onChange={handleCommentsChange}
                  required
                />
                </div>
              {localStorage.getItem("Priority_Value") === "Add" ? (<button type="submit" className="btn btn-primary">Add</button>)
                : localStorage.getItem("Priority_Value") === "Edit" ? (<button type="submit" className="btn btn-primary">Save</button>)
                : (<button type="submit" className="btn btn-primary" onClick={handleSubmitDelete}>Delete</button>)

                }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriorityCase;
