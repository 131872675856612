import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  PanControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
function LeafletMap() {
  const defaultIcon = L.icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"), // Path to the default marker icon
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  });
  return (
    <MapContainer
      center={[15.592007, 120.904953]}
      zoom={18}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer
        url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWx2aW4wMjA2IiwiYSI6ImNrdWM3NnA0azB4dWwycm84djV0NmQ2dHAifQ.DRPMBpXwngKDcyMpH68wgw"
        attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a> | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={[15.592006673170623, 120.90496036136503]}
        icon={defaultIcon}
      >
        <Popup>Health Center, Evacuation Center, Baranggay Hall</Popup>
      </Marker>
    </MapContainer>
  );
}

export default LeafletMap;
