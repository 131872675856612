import React, { } from 'react';
import Admin from "../../components/shared/Admin";
import MyChartComponent from '../../components/charts/chartComponent';
const Dashboard = () => {
	return <Admin title={"Dashboard"} content={<MyChartComponent />}
		
	 />;
};

export default Dashboard;
