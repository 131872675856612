import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import alertify from "alertifyjs"
import logo from "../../assets/images/icon/log.png";
import loginBg from "../../assets/images/proj/login.jpg";
import { auth, firestore} from "../../firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import {getDoc, collection, getDocs,query,where, doc} from "firebase/firestore";
import ForgotPasswordModal from "../../components/modal/forgotPassword";
const Login = () => {
  alertify.set('notifier', 'position', 'top-center');
  const altLabelImage = "banner-placeholder";
  const logoStyle = {
    width: "90px",
  };
  const imageContainer = {
    position: "relative",
    display: "inline-block",
    objectFit: "cover",
  };
  const fadeShadow = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))",
    zIndex: "1",
  };
  const containerLabel = {
    zIndex: "2",
  };

  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [items, setItems] = useState([]);

  const formValue = {
    username: username,
    password: password,
  };

  const postDataHandler = async (e) => {
    e.preventDefault();
    const elementsWithInvalidClass = document.querySelectorAll('.is-invalid');
    if (elementsWithInvalidClass.length === 0) {
    
    try {
      const user = document.getElementById("username");
      const pass = document.getElementById("password");
      const res = await signInWithEmailAndPassword(auth, username, password);
      const docAdmin = doc(firestore, "admin", res.user.uid);
      const docSnapAdmin = await getDoc(docAdmin);
      //getting data for address and user_status
      
      const docUser = doc(firestore, "user", res.user.uid);
      const docSnapUser = await getDoc(docUser);
     
      if (docSnapUser.exists() || docSnapAdmin.exists()) {
        var userData;
        if (docSnapUser.exists()) {
          userData=docSnapUser.data();
        } else if (docSnapAdmin.exists()) {
          userData =docSnapAdmin.data();
        }
        alertify.success("Login Successful");
        localStorage.setItem(
          "logged_address",
          userData.address
        );
        localStorage.setItem(
          "logged_user",
          userData.username
        );
        localStorage.setItem(
          "logged_user_type",
          userData.user_type
        );
        localStorage.setItem(
          "logged_phoneNumber",
          userData.contact_number
          //wala pa yung address at usertype
        );
        localStorage.setItem(
          "logged_fullname",
          userData.first_name + " " + userData.middle_name + " " + userData.last_name
        );
              
        localStorage.setItem(
          "logged_email",
          userData.email
        );
        if (userData.user_type === "admin") {
          window.location.href = window.location.origin + "/admin/dashboard/";
        } else {
          window.location.href = window.location.origin + "/home";
        }     
      } else {
      // docSnap.data() will be undefined in this case
      user.classList.add("is-invalid");
      pass.classList.add("is-invalid");
    }
    } catch (error) {
      alertify.error("Error Logging In.");
     }
    }else{
      alertify.error("Fill All The Fields!!");
    }
      
    
  };

  function emptyFields(id, value, input_id) {
    var usernamePattern = new RegExp("^[a-zA-Z0-9_]{6,}$");
    const nameRegex = /^[a-zA-Z\s]{2,30}$/;
    const phoneNumberRegex = /^\d{11}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   
    if (input_id === "firstName") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "middleName") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "lastname") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "contactNumber") {
      id.classList.toggle("is-invalid", value.length !== 11);
      id.classList.toggle("is-valid", phoneNumberRegex.test(value) === true);
    } else if (input_id === "password") {
      id.classList.toggle("is-invalid", passwordRegex.test(value) === false);
      id.classList.toggle("is-valid", passwordRegex.test(value) === true);
    } else if (input_id === "username") {
      id.classList.toggle("is-invalid", emailPattern.test(value) === false);
      id.classList.toggle("is-valid", emailPattern.test(value) === true);
    } else if (input_id === "email") {
      id.classList.toggle("is-invalid", emailPattern.test(value) === false);
      id.classList.toggle("is-valid", emailPattern.test(value) === true);
    } else {
      id.classList.toggle("is-invalid", value === "");
      id.classList.toggle("is-valid", value !== "");
    }
  }

  const [isModalVisible, setModalVisible] = useState(false);

  const handleForgotPasswordClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  return (
    <div
      className="modal d-block"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-body m-0 p-0 row gap-3 text-dark d-flex justify-content-center">
            {/* Login Card */}
            <div className="card col-10 col-sm-9 col-md-9 col-lg-5 col-xl-4 rounded-4 shadow border-light">
              <div className="card-body">
                <div className="header col row m-0">
                  <div className="col-3 d-flex justify-content-center align-items-center m-0 p-0 ">
                    <img
                      src={logo}
                      alt={altLabelImage}
                      className="img-fluid"
                      style={logoStyle}
                    />
                  </div>
                  <div className="col px-0 mx-2 d-flex align-items-end">
                    <div className="col">
                      <p className="th-fs-1 th-fw-bold m-0">E-handa</p>
                      <span className="fw-normal m-0 mb-3">
                        Barangay Disaster Preparedness System
                      </span>
                    </div>
                  </div>
                </div>
                <div className="content col row m-0 pt-4">
                  <p className="th-fs-2 th-fw-semibold m-0">
                    Create your Account
                  </p>
                  <small>Let us know you're safe.</small>
                  <div className="row gap-2 m-0 my-3">
                    <Link
                      className="btn btn-light text-uppercase border"
                      to="/register"
                    >
                      register
                    </Link>
                    <div className="d-flex align-items-center gap-2 my-2">
                      <hr className="col m-0" />
                      <span>or</span>
                      <hr className="col m-0" />
                    </div>

                    <form onSubmit={(e) => postDataHandler(e)}>
                      <div className=" custom-field col-12 col-lg-12 mb-1">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          name="username"
                          id="email"
                          className="form-control w-100 mb-3"
                          onChange={(e) => {
                            setUsername(e.target.value);
                            emptyFields(e.target, e.target.value, e.target.id);
                          }}
                          value={username}
                          required
                        />
                      </div>
                      <div className=" custom-field col-12 col-lg-12 mb-1">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control w-100 mb-3"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            emptyFields(e.target, e.target.value, e.target.id);
                          }}
                          value={password}
                          required
                        />
                      </div>
                      <button className="btn th-btn-primary text-uppercase mt-3 mb-2 w-100">
                        login
                      </button>
                    </form>
                  </div>
                  <div className="text-start col-12 th-fs-3">
                    <span>
                      Forgot your password?{" "}
                      <button className="btn btn-link" onClick={handleForgotPasswordClick}>Click Here</button>
                      <ForgotPasswordModal showModal={isModalVisible} onClose={handleCloseModal} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Content Card */}
            <div
              className="card col position-relative d-none d-sm-none d-md-none d-lg-block rounded-4 p-0 shadow "
              style={imageContainer}
            >
              <div style={fadeShadow} className=" rounded-4"></div>
              <img
                className="card-img h-100 rounded-4"
                src={loginBg}
                alt={altLabelImage}
              />
              <div className="card-img-overlay d-flex align-content-end container">
                <div className="row align-items-end">
                  <p
                    className="th-h3 th-fw-semibold text-light"
                    style={containerLabel}
                  >
                    Safety First, Preparedness Always: Embrace Calamity
                    Readiness!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     
  );
};
export default Login;