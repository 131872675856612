import { useState, useRef, useEffect } from "react";
import postImage from "../../assets/images/placeholder/post-placeholder.jpg";

const PostList = ({ header, posts }) => {
  const [lists, setLists] = useState(posts);
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const imageRef = useRef(null);
  const blankImage = null;
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid black";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });

  return (
    <>
      <div className="row justify-content-start mb-5 mx-0">
        <div className="col-lg-9 col-12 " ref={sectionBorderRef}>
          <h3 ref={sectionTitleRef}>Announcement</h3>
        </div>
      </div>
      {lists.map((list) => (
        <div key={list.postId} className="row justify-content-start mb-5 mx-0">
          <div className="col-lg-9 col-12 mb-3">
            <img
              src={list.imageName ?? postImage}
              // src={postImage}
              alt={list.altLabelImage}
              className="img img-fluid w-100"
            />
          </div>
          <div className="col-lg-9 col-12">
            <h3>{list.title}</h3>
            <p>{list.body}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default PostList;
