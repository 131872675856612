import React, { useEffect, useState } from "react";
import Admin from "../../components/shared/Admin";
import SimpleTable from "../../components/tables/SimpleTable";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
const AdminPreparation = () => {
  const tableHeaders = [
    { data: "id", title: "id" },
    { data: "name", title: "Name" },
    { data: "category", title: "Category" },
    { data: "percentage", title: "Percentage" },
    { data: "remarks", title: "Remarks" },
    { data: "percentage", title: "Color" },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    //fetch data from firebase
    const fetchData = async () => {
      //getDocs
      const collection1 = await getDocs(
        collection(firestore, "Preparation_Checklist")
      );
      const documents = [];
      //push sa array
      collection1.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      setData(documents);
    };

    fetchData();
  }, []); // The empty array [] ensures that this effect runs once after the initial render

  const tableRows = data.map((td, index) => {
    const checkboxes = {
      1: td.checkbox1,
      2: td.checkbox2,
      3: td.checkbox3,
      4: td.checkbox4,
      5: td.checkbox5,
      6: td.checkbox6,
      7: td.checkbox7,
      8: td.checkbox8,
      9: td.checkbox9,
      10: td.checkbox10,
    };
    const checkboxValues = {
      checkboxValue1: td.checkboxValue1,
      checkboxValue2: td.checkboxValue2,
      checkboxValue3: td.checkboxValue3,
      checkboxValue4: td.checkboxValue4,
      checkboxValue5: td.checkboxValue5,
      checkboxValue6: td.checkboxValue6,
      checkboxValue7: td.checkboxValue7,
      checkboxValue8: td.checkboxValue8,
      checkboxValue9: td.checkboxValue9,
      checkboxValue10: td.checkboxValue10,
    };

    const selectedCheckboxValues = Object.keys(checkboxes)
      .filter((checkbox) => !checkboxes[checkbox])
      .map((checkbox) => checkboxValues["checkboxValue" + checkbox]) // Map to corresponding checkboxValues
      .join(", ");

    return {
      id: index + 1,
      name: td.name,
      category: td.category,
      percentage: td.percentage,
      remarks: selectedCheckboxValues,
    };
  });
  /* const tableRows = [
    {
      name: "Juan Dela Cruz",
      category: "Earthquake",
      percentage: "50%",
      remarks: "unsatisfactory",
      color: "yellow",
    },
    {
      name: "Cardo Dimagiba",
      category: "Fire",
      percentage: "30%",
      remarks: "urgent",
      color: "red",
    },
    {
      name: "Daniel Cassanova",
      category: "Typhoon",
      percentage: "80%",
      remarks: "satisfactory",
      color: "green",
    },
  ];
 */
  const tableHeadContent = "preparationsHeads";
  const tableBodyContent = "preparationsRows";
  return (
    <Admin
      title={"Checklist Progress"}
      table={
        <SimpleTable
          heads={tableHeaders}
          rows={tableRows}
          tableHeadContent={tableHeadContent}
          tableBodyContent={tableBodyContent}
        />
      }
    />
  );
};

export default AdminPreparation;
