import React, { useState } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import {auth} from "../../firebase"
import alertify from 'alertifyjs';


const ConfirmPasswordModal = ({ showModal, onClose, onSuccessReauthentication }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = (e) => {
      setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      const user = auth.currentUser;
      const email = auth.currentUser.email;
    
    // TODO(you): prompt the user to re-provide their sign-in credentials
    const credential = EmailAuthProvider.credential(email, password);
    
    reauthenticateWithCredential(user, credential).then(() => {
        alertify.success("User Reauthenticated!")
        onSuccessReauthentication();
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
          
          console.log(errorCode + ": " + errorMessage);
    });
    onClose();
  };

  return (
    <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Password</h5>
            <button type="button" className="close btn-danger" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mt-3">
            <form onSubmit={handleSubmit}>
              <div className="custom-field col-12 col-lg-12 mb-1">
                <label htmlFor="email" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control w-100 mb-3"
                  id="email"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                </div>
                <button type="submit" className="btn btn-primary mt-4">Confirm</button>
            </form>
                      
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPasswordModal;
