import { useRef, useEffect } from "react";
import Footer from "../../components/shared/Footer";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/images/placeholder/banner-placeholder.jpg";
import poster from "../../assets/images/proj/p4.jpg";
import img1 from "../../assets/images/proj/p5.jpg";
import img2 from "../../assets/images/proj/p6.jpg";
import img3 from "../../assets/images/proj/p7.jpg";
import img4 from "../../assets/images/proj/p8.jpg";
const News = () => {
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const altLabelImage = "banner-placeholder";
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid #242424";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });
  const image = {
      height: "80vh",
    },
    cardimage = {
      height: "150px",
      width: "100%",
      objectFit: "cover",
    },
    customStyle = {
      marginTop: "100px",
    },
    hiddenContent = {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
    };
  const news = [
    {
      id: 1,
      imageName: img1,
      title: "Earthquake Drill",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "DepEd and CHED officials agreed, saying students and teachers must be equipped with knowledge on mitigating and managing hazards and risks brought by natural disasters like earthquakes.",
    },
    {
      id: 2,
      imageName: img2,
      title: "CPR Training",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "What to do once someone is not breathing? Learn how to do chest compressions as you do it on the beat of the song Staying alive.",
    },
    {
      id: 3,
      imageName: img3,
      title: "First Aid Application Exercise",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "Injuries sustained while a disaster is occuring or has occured? Learn how to apply first aid as this can definitely save a life.",
    },
    {
      id: 4,
      imageName: img4,
      title: "Fire Prevention Month Exercises",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "During the hottest month of the year, it is customary to exhibit drills that are useful to prevent the spread of fire ",
    },
  ];

  return (
    <div className="container py-4" style={customStyle}>
      <div className="row m-0">
        {/* Section Title */}
        <div className="row justify-content-start mb-3 ">
          <div className="col-12 lh-sm" ref={sectionBorderRef}>
            <label className="th-fs-1 " ref={sectionTitleRef}>
              Events
            </label>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-12 row gap-2">
          <div className="row gap-2 m-0 p-0">
            {/* Cards */}
            <div className="col-lg col-12">
              <div className="row gap-3 mx-0">
                {news.map((article) => {
                  return (
                    <div className="col-lg-5 col-12 m-0 p-0">
                      <Link
                        className="btn btn-light card m-0 p-0 text-start text-decoration-none "
                        to={`/news/${article.id}`}
                      >
                        <img
                          src={article.imageName}
                          className="card-img-top"
                          alt="..."
                          style={cardimage}
                        />
                        <div className="card-body">
                          <h5 className="card-title th-fw-semibold">
                            {article.title}
                          </h5>
                          <p className="card-text ">
                            <label className="pe-auto" style={hiddenContent}>
                              {article.body}
                            </label>
                          </p>
                          <p className="card-text ">
                            <small className="text-secondary">
                              {article.createdAt}
                            </small>
                          </p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md col-12 m-0 p-0">
          <img src={poster} className="img-fluid w-100" style={image} />
        </div>
      </div>
    </div>
  );
};
export default News;
