import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import postImage from "../../assets/images/placeholder/post-placeholder.jpg";

const CardList = ({ header, posts }) => {
  const [lists, setLists] = useState(posts);
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid black";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });

  return (
    <>
      <div className="row justify-content-start mb-5">
        <div className="col-lg-9 col-12" ref={sectionBorderRef}>
          <h3 ref={sectionTitleRef}>{header}</h3>
        </div>
      </div>
      <div className="row justify-content-start mb-5">
        <div className="col-lg-9 col-12">
          <div className="row">
            {lists.map((list) => (
              <div className="col-lg-4 mb-3">
                <div key={list.prepsId} className="card border-0 shadow-sm">
                  <img
                    src={list.imageName}
                    alt={list.altLabelImage}
                    className="card-img-top"
                    style={{
                      height: "170px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="card-body">
                    <p className="card-text">{list.title}</p>
                    <Link className="btn btn-sm btn-dark" to="/preparation">
                      Learn more
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardList;
