import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import bannerImage from "../../assets/images/placeholder/banner-placeholder.jpg";
import logo from "../../assets/images/icon/log.png";
import StepForm from "../../components/forms/StepForm";
import loginBg from "../../assets/images/proj/login.jpg";
const Register = () => {
  const altLabelImage = "banner-placeholder";
  const logoStyle = {
    height: "80px",
    width: "80px",
    borderRadius: "50%",
  };

  const containerLabel = {
    zIndex: "2",
  };
  const title = "E-handa",
    subTitle = "Barangay Disaster Preparedness System",
    label = "Create your account",
    subLabel = "Let us know your safe.";
  const imageContainer = {
    position: "relative",
    display: "inline-block",
  };
  const fadeShadow = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))",
    zIndex: "1",
  };

  return (
    <div
      className="modal d-block"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-body m-0 p-0 row gap-3 text-dark d-flex justify-content-center">
            {/* Login Card */}
            <div className="card col-10 col-sm-9 col-md-9 col-lg-5 col-xl-4 rounded-4 shadow">
              <div className="card-body">
                <div className="header col row m-0">
                  <div className="col-3 d-flex justify-content-center align-items-center m-0 p-0 ">
                    <img
                      src={logo}
                      alt={altLabelImage}
                      className="img-fluid"
                      style={logoStyle}
                    />
                  </div>
                  <div className="col px-0 mx-2 d-flex align-items-end">
                    <div className="col">
                      <p className="th-fs-1 th-fw-bold m-0">{title}</p>
                      <span className="m-0 mb-3">{subTitle}</span>
                    </div>
                  </div>
                </div>
                <div className="content col row m-0 pt-4">
                  <p className="th-fs-2 th-fw-semibold m-0">{label}</p>
                  <small>{subLabel}</small>

                  <StepForm />
                  <div className="text-start col-12 th-fs-3">
                    <span>
                      Already have an account?
                      <Link
                        className="btn-link text-decoration-none"
                        to="/login"
                      >
                        {" "}
                        Click here
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Content Card */}
            <div
              className="card col position-relative d-none d-sm-none d-md-none d-lg-block rounded-4 p-0 shadow "
              style={imageContainer}
            >
              <div style={fadeShadow} className=" rounded-4"></div>
              <img
                className="card-img h-100  rounded-4"
                src={loginBg}
                alt={altLabelImage}
              />
              <div className="card-img-overlay d-flex align-content-end container">
                <div className="row align-items-end">
                  <p
                    className="th-h3 th-fw-semibold text-light"
                    style={containerLabel}
                  >
                    Safety First, Preparedness Always: Embrace Calamity
                    Readiness!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
