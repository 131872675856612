import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import bannerImage from "../../assets/images/placeholder/banner-placeholder.jpg";

import img1 from "../../assets/images/proj/p3.jpg";
import img2 from "../../assets/images/proj/p7.jpg";
import img3 from "../../assets/images/proj/volcanicEruption.jpg";
const AnnouncementShow = () => {
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const altLabelImage = "banner-placeholder";
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid #242424";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });
  const customStyle = {
    marginTop: "100px",
  };
  const { id } = useParams();
  const anouncement = [
    {
      id: 1,
      imageName: img1,
      title:
        "Disaster Preparedness Tips to Help Seniors in Case of an Emergency",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "Tornadoes, hurricanes, earthquakes, and other natural disasters arise quickly, and though it is essential for all of us to have an emergency plan in place, it’s extremely important for seniors, who may be challenged with mobility or chronic health concerns. Since September is National Preparedness Month, it is the ideal opportunity to make sure the seniors you love are well-equipped when it comes to disaster preparedness or another potential emergency. Taking these three steps is an easy starting point.",
    },
    {
      id: 2,
      imageName: img2,
      title: "Barangay First aid kit distribution",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "Ang kaligtasan at seguridad sa panahon ng sakuna ay dapat natin malaman. Maagang paghahanda at pagbibigay ng mga gamit sa oras na sakuna ay lalong mahalaga. Ang mga kabataan ng Barangay Alicia ay magiging handa kung sakali man na magkaroon ng sakuna o hindi inaasahang pangyayari Maraming Salamat sa lahat ng naki-isa at pumunta sa araw na ito.",
    },
    {
      id: 3,
      imageName: img3,
      title: "Preparedness Seminar at the Barangay covered court",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body: "This year, Aboitiz Construction is also set to implement disaster preparedness programs in more communities where it operates. The company is currently involved in the Aboitiz Foundation-led Disaster Risk Reduction Training Program for the Department of Education teachers. Aboitiz Construction is the construction arm of the Aboitiz Group of Companies, one of the prominent conglomerates in the Philippines. Aboitiz Construction is a privately-held company, with a 45-year track record in value construction and engineering that advances businesses and communities by building a better future.",
    },
  ];
  return (
    <>
      <div className="container py-4" style={customStyle}>
        <div className="row m-0">
          {/* Section Title */}
          <div className="row mb-3">
            <div className="col-12 lh-sm" ref={sectionBorderRef}>
              <label className="th-fs-1 " ref={sectionTitleRef}>
                Announcement /
              </label>
              <label className="th-fs-1 th-fw-bold">
                {anouncement[id - 1].title}
              </label>
              <br />
              <small className="text-secondary">
                {anouncement[id - 1].createdAt}
              </small>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex justify-content-center ">
          <div className="col-9 my-5">
            <div className="d-flex mb-4">
              <img
                src={anouncement[id - 1].imageName}
                className="img-fluid h-75 w-100"
              />
            </div>
            <p className="th-fs-3 m-0 ">{anouncement[id - 1].body}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementShow;
