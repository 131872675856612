import { useState, useRef, useEffect } from "react";
import logoImage from "../../assets/images/placeholder/logo-placeholder.png";

const CardList = ({ header, posts }) => {
  const [lists, setLists] = useState(posts);
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid black";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });
  return (
    <>
      <div className="row justify-content-start mb-5 mx-0">
        <div className="col-lg-9 col-12 " ref={sectionBorderRef}>
          <h3 ref={sectionTitleRef}>{header}</h3>
        </div>
      </div>
      <div className="row justify-content-start mb-5 mx-0">
        <div className="col-lg-12 col-12">
          <div className="row">
            {lists.map((list) => (
              <div
                key={list.orgId}
                className="col-xl-6 col-lg-6 col-md-6 col-sm-7 mb-3"
              >
                <div className="row">
                  <div className="col-lg-3 col-4">
                    <img
                      src={list.imageName}
                      alt={list.altImageLabel}
                      className="img img-fluid "
                    />
                  </div>
                  <div className="col-lg col lh-base">
                    <p className="th-fs-2 th-fw-semibold">{list.title}</p>
                    <p>
                      <strong>Hotline: </strong>
                      {list.hotline}
                      <br />
                      <strong>Contact: </strong>
                      {list.contact}
                      <br />
                      <strong>Facebook: </strong>
                      {list.facebook}
                      <br />
                      <strong>Twitter: </strong>
                      {list.twitter}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardList;
