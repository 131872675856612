import React from "react";
import { Outlet } from "react-router-dom";
import Admin from "../../components/shared/Admin";
import Header from "../../components/shared/Header";
const InternalLayout = () => {
	const navItemList = [];
	return (
		<>
			<Header lists={navItemList} isVisible={false} />

			<Outlet />
		</>
	);
};
export default InternalLayout;
