import React, { useEffect, useState } from "react";
import Admin from "../../components/shared/Admin";
import SimpleTable from "../../components/tables/SimpleTable";
import { firestore } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";

const Case = () => {
  const tableHeaders = [
    { data: "docID", title: "docID" },
    { data: "id", title: "#" },
    { data: "name", title: "Name" },
    { data: "category", title: "Category" },
    { data: "status", title: "Status" },
    { data: "comments", title: "Comments" },
    { title: "Actions" },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    //fetch data from firebase
    const fetchData = async () => {
      //getDocs
      const collection1 = await getDocs(
        collection(firestore, "Priority_Cases")
      );
      const documents = [];
      //push sa array
      collection1.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      setData(documents);
    };

    fetchData();
  }, []); // The empty array [] ensures that this effect runs once after the initial render

  const tableRows = data.map((item, index) => {
    return {
      docID: item.id,
      id: index + 1,
      name: item.name,
      category: item.category,
      status: item.status,
      comments: item.comments,
    };
  });
  /* const tableRows = [
    { name: "Juan Dela Cruz", category: "Earthquake", status: "Wala" },
    { name: "Cardo Dimagiba", category: "Fire", status: "Meron" },
    { name: "Daniel Cassanova", category: "Typhoon", status: "Kulang" },
  ]; */

  const tableHeadContent = "casesHeads";
  const tableBodyContent = "casesRows";
  return (
    <Admin
      title={"Priority Cases"}
      table={
        <SimpleTable
          heads={tableHeaders}
          rows={tableRows}
          tableHeadContent={tableHeadContent}
          tableBodyContent={tableBodyContent}
        />
      }
    />
  );
};

export default Case;
