import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import staticMap from "../../assets/images/proj/staticMap.png";
import LeafletMap from "./LeafletMap";
const Evacuation = () => {
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const altLabelImage = "banner-placeholder";
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid #242424";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });
  const customStyle = {
      marginTop: "100px",
    },
    cardimage = {
      height: "55vh",
      width: "100%",
      objectFit: "object-fit",
      cursor: "pointer",
    },
    hiddenContent = {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
    };
  const legends = [
    {
      name: "Barangay: ",
      description:
        "The Local Government unit in charge of management and monitoring the governed area. This is also one of the places where residents can get vital aid with regards to disaster preparedness.",
    },
    {
      name: "Health Clinic: ",
      description:
        "Check ups, medicines and other seminars are offered in this local institution.",
    },
    {
      name: "Evacuation center: ",
      description:
        "The designated safe center for residents affected by the disaster. This is where local reliefs are centralized in the aftermath of a disaster.",
    },
  ];
  const handleMapLink = () => {
    window.location.href =
      "https://www.google.com/maps/place/San+Agustin,+sto+domingo+nueva+ecija/@15.6027942,120.8961194,17z/data=!3m1!4b1!4m6!3m5!1s0x33972bcef856c8ef:0x4dc9c193f4334e29!8m2!3d15.602789!4d120.8986943!16s%2Fg%2F11jsqgh_1k?entry=ttu";
  };
  const label =
    "Map of the barangay we are helping with regards to disaster preparedness. We indicated map legends for the accurate location of said facilities.";
  return (
    <div className="container py-4" style={customStyle}>
      <div className="row m-0">
        {/* Section Title */}
        <div className="row justify-content-start mb-2">
          <div className="col-12 lh-sm" ref={sectionBorderRef}>
            <label className="th-fs-1 " ref={sectionTitleRef}>
              Map Location
            </label>
          </div>
        </div>
        <div className="col-lg-12 col-12 row gap-2">
          <div className="row gap-2 m-0 p-0">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="col-10 my-4">
                <LeafletMap></LeafletMap>
              </div>
            </div>
            <div className="col-12">
              <label className="th-fs-3 my-3">{label}</label>
              {legends.map((legend) => {
                return (
                  <label className="th-fs-3 my-1">
                    <strong className="th-fs-3 th-fw-bold">
                      {legend.name}
                    </strong>
                    {legend.description}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evacuation;
