import React, { useEffect, useState } from "react";
import Admin from "../../components/shared/Admin";
import SimpleTable from "../../components/tables/SimpleTable";
import { firestore } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const User = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    //fetch data from firebase
    const fetchData = async () => {
      //getDocs
      const collection1 = await getDocs(collection(firestore, "user"));
      const collection2 = await getDocs(collection(firestore, "admin"));
      const documents = [];
      //push sa array
      collection1.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      collection2.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      setData(documents);
    };

    fetchData();
  }, []); // The empty array [] ensures that this effect runs once after the initial render

  const tableHeaders = [
    { data: "id", title: "Id" },
    { data: "name", title: "Name" },
    { data: "email", title: "Email" },
    { data: "address", title: "Address" },
    { data: "userType", title: "Type" },
  ];

  const tableRows = data.map((item, index) => {
    return {
      id: index + 1,
      name: item.first_name + " " + item.middle_name + " " + item.last_name,
      email: item.email,
      address: item.address,
      userType: item.user_type,
    };
  });
  const tableHeadContent = "accountsHeads";
  const tableBodyContent = "accountsRows";
  return (
    <Admin
      title={"Users"}
      table={
        <SimpleTable
          heads={tableHeaders}
          rows={tableRows}
          tableHeadContent={tableHeadContent}
          tableBodyContent={tableBodyContent}
        />
      }
    />
  );
};

export default User;
