import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
const ExternalLayout = () => {
  const navItemList = [
    {
      navId: 1,
      redirectTo: "/home",
      name: "Home",
    },
    {
      navId: 2,
      redirectTo: "/announcement",
      name: "Announcement",
    },
    {
      navId: 3,
      redirectTo: "/news",
      name: "Events",
    },
    {
      navId: 4,
      redirectTo: "/preparation",
      name: "Preparation",
    },
    {
      navId: 4,
      redirectTo: "/evacuation",
      name: "Evacuation Map",
    },
  ];
  return (
    <>
      <Header lists={navItemList} isVisible={true} />
      <Outlet />
      <Footer />
    </>
  );
};
export default ExternalLayout;
