import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import { updateDoc, getDoc, doc, query, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import alertify from "alertifyjs";
import ConfirmPasswordModal from "../../components/modal/confirmPassword";

const Profile = () => {
  alertify.set("notifier", "position", "top-center");
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const sectionMargin = useRef(null);
  const [isInputDisabled, setInputDisabled] = useState(true);
  const [isButtonVisible, setButtonVisible] = useState(true);
  const [currUser, setCurrUser] = useState("");
  const [currName, setCurrName] = useState("");
  const [currEmail, setCurrEmail] = useState("");
  const [currAddress, setCurrAddress] = useState("");
  const [currContactNumber, setCurrContactNumber] = useState("");
  const [currUserType, setCurrUserType] = useState("");
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, call the setData function
        setData(user.uid);
      } else {
        // User is signed out, handle accordingly
        // For example, redirect to login page
      }
    });

    return () => {
      // Unsubscribe the observer when the component unmounts
      unsubscribe();
    };
  }, []);

  async function setData(uid) {
    try {
      const docRef = doc(firestore, "user", uid); // Assuming your Firestore collection is named "users"
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        setCurrAddress(userData.address);
        setCurrContactNumber(userData.contact_number);
        setCurrEmail(userData.email);
        setCurrName(
          userData.first_name +
            " " +
            userData.middle_name +
            " " +
            userData.last_name
        );
        setCurrUser(userData.username);
      } else {
        // Handle the case where the document does not exist
        console.log("No such document!");
      }
    } catch (error) {
      // Handle errors that occur during data retrieval
      console.error("Error getting document:", error);
    }
  } //handling of disable
  const handleDisableInput = () => {
    if (isInputDisabled === true && isButtonVisible === true) {
      setInputDisabled(false);
      setButtonVisible(false);
    } else {
      saveData();
    }
  };
  function emptyFields(id, value, input_id) {
    var usernamePattern = new RegExp("^[a-zA-Z0-9_]{6,}$");
    const nameRegex = /^[a-zA-Z\s]{2,30}$/;
    const phoneNumberRegex = /^\d{11}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (input_id === "firstName") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "middleName") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "lastname") {
      id.classList.toggle("is-invalid", nameRegex.test(value) === false);
      id.classList.toggle("is-valid", nameRegex.test(value) === true);
    } else if (input_id === "contact_number") {
      id.classList.toggle("is-invalid", phoneNumberRegex.test(value) === false);
      id.classList.toggle("is-valid", phoneNumberRegex.test(value) === true);
    } else if (input_id === "password") {
      id.classList.toggle("is-invalid", passwordRegex.test(value) === false);
      id.classList.toggle("is-valid", passwordRegex.test(value) === true);
    } else if (input_id === "username") {
      id.classList.toggle("is-invalid", usernamePattern.test(value) === false);
      id.classList.toggle("is-valid", usernamePattern.test(value) === true);
    } else if (input_id === "email") {
      id.classList.toggle("is-invalid", emailPattern.test(value) === false);
      id.classList.toggle("is-valid", emailPattern.test(value) === true);
    } else {
      id.classList.toggle("is-invalid", value === "");
      id.classList.toggle("is-valid", value !== "");
    }
  }
  const handleSuccessfulReauthentication = async () => {
    try {
      const user = auth.currentUser;
      var userData = doc(firestore, "user", user.uid);
      const updateDataSet = await updateDoc(userData, {
        email: currEmail,
        username: currUser,
        contact_number: currContactNumber,
        address: currAddress,
      })
        .then(() => {
          setInputDisabled(true);
          setButtonVisible(true);
          alertify.success("User Profile Updated Successfuly!");
        })
        .catch((error) => {
          alertify.error("User Profile Failed to Update!");
        });
    } catch (e) {
      alertify.error("User Profile Failed to Update!");
    }
  };

  //handling of saving data to firestore
  function saveData() {
    const elementsWithInvalidClass = document.querySelectorAll(".is-invalid");
    if (elementsWithInvalidClass.length === 0) {
      handleConfirmPasswordClick();
    } else {
      alertify.error("Fill All the Fields!");
    }
  }
  const [isModalVisible, setModalVisible] = useState(false);

  const handleConfirmPasswordClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid #242424";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
    sectionMargin.current.style.marginTop = "90px";
  });
  return (
    <div className="container col col-lg py-4" ref={sectionMargin}>
      <div className="col-lg-8 row gap-2">
        {/* Section Title */}
        <div className="row justify-content-start mb-3 ">
          <div className="col-7" ref={sectionBorderRef}>
            <h3 ref={sectionTitleRef}>Profile</h3>
          </div>
          {currUserType == "admin" && (
            <div className="col-5 ">
              <Link to="/admin/dashboard" className="btn btn-light float-end">
                Go to Admin Panel
              </Link>
            </div>
          )}
        </div>
        {/* firstname,middlename,lastname */}
        <div className="row gap-2 m-0">
          <div className=" custom-field col-12 col-lg mb-1">
            <label className="form-label">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={currName}
              className="form-control col-12 col-lg"
              disabled
            />
          </div>
          <hr className="my-2" />
        </div>
        {/* username,password */}
        <div className="row gap-2 m-0 col-12 col-lg-8">
          <div className=" custom-field col-12 col-lg mb-1">
            <label className="form-label">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              className="form-control col col-lg"
              value={currUser}
              onChange={(e) => {
                setCurrUser(e.target.value);
                emptyFields(e.target, e.target.value, e.target.id);
              }}
              disabled={isInputDisabled}
            />
          </div>
        </div>
        {/* email,contact_number,address */}
        <div className="row gap-2 m-0">
          <hr className="my-2" />
          <div className=" custom-field col-12 col-lg mb-1">
            <label className="form-label">E-mail</label>
            <input
              type="text"
              name="email"
              id="email"
              className="form-control col-12 col-lg"
              value={currEmail}
              onChange={(e) => {
                setCurrEmail(e.target.value);
                emptyFields(e.target, e.target.value, e.target.id);
              }}
              disabled
            />
          </div>
          <div className=" custom-field col-12 col-lg mb-1">
            <label className="form-label">Contact Number</label>
            <input
              type="number"
              name="contact_number"
              id="contact_number"
              className="form-control col-12 col-lg"
              value={currContactNumber}
              onChange={(e) => {
                setCurrContactNumber(e.target.value);
                emptyFields(e.target, e.target.value, e.target.id);
              }}
              disabled={isInputDisabled}
            />
          </div>
        </div>
        <div className="row gap-2 m-0">
          <hr className="my-2" />
          <div className=" custom-field col-12 col-lg mb-1">
            <label className="form-label">Address</label>
            <textarea
              type="text"
              name="address"
              id="address"
              className="form-control col-12 col-lg"
              value={currAddress}
              onChange={(e) => {
                setCurrAddress(e.target.value);
                emptyFields(e.target, e.target.value, e.target.id);
              }}
              disabled={isInputDisabled}
            ></textarea>
          </div>
        </div>
        <div className="d-flex py-2 justify-content-end">
          {isButtonVisible ? (
            <button
              className="btn th-btn-primary text-uppercase"
              onClick={handleDisableInput}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                className="btn th-btn-primary text-uppercase"
                onClick={handleDisableInput}
              >
                Save
              </button>
              <ConfirmPasswordModal
                showModal={isModalVisible}
                onClose={handleCloseModal}
                onSuccessReauthentication={handleSuccessfulReauthentication}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
