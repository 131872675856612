import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import postImage from "../../assets/images/placeholder/post-placeholder.jpg";
import featuredImg1 from "../../assets/images/proj/p5.jpg";
import featuredImg2 from "../../assets/images/proj/p7.jpg";
import featuredImg3 from "../../assets/images/proj/volcanicEruption.jpg";
import featuredImg4 from "../../assets/images/proj/sunog.jpg";
const FeaturedList = ({ header }) => {
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const imageRef = useRef(null);

  const cardimage = {
    height: "140px",
    width: "100%",
    objectFit: "cover",
  };
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid black";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });

  const articles = [
    {
      id: 1,
      image: featuredImg1,
      category: "Events",
      title: "Bureau of Fire Protection: Drill Event",
      body: "lorem",
      date: "May 7 2023",
    },
    {
      id: 2,
      image: featuredImg2,
      category: "Operations",
      title: "Rescued civilian to a burning establishment",
      body: "lorem",
      date: "April 27 2023",
    },
    {
      id: 3,
      image: featuredImg3,
      category: "Nature",
      title: "Preparedness for possible Volcano Eruptions",
      body: "lorem",
      date: "April 27 2023",
    },
    {
      id: 4,
      image: featuredImg4,
      category: "Disaster",
      title:
        "Increasing number of houses burning, accumulated 300 families are affected within an hour",
      body: "Quezon City: Around 300 families affected after a fire gutted their community in Tandang Sora, Quezon City, Reaching the first alarm at 3:45 AM. Local Authorities have prepared a rescue operations and provide an evacuations.",
      date: "April 27 2023",
    },
  ];
  const recentPost = articles.pop();
  return (
    <>
      <div className="row justify-content-center mb-5 mx-0">
        <div className="col-12" ref={sectionBorderRef}>
          <h3 ref={sectionTitleRef}>{header}</h3>
        </div>
      </div>

      <div className="row justify-content-center mb-5  mx-0">
        <div className="row col-12 p-0 ">
          <div className="col-lg-6 col-12">
            {articles.map((article) => {
              return (
                <div className="d-flex gap-2 mb-3" key={article.id}>
                  <div className="col-lg col">
                    <img
                      src={article.image}
                      className="img img-fluid w-100 rounded-2"
                      style={cardimage}
                    />
                  </div>
                  <div className="col-lg col">
                    <strong className="text-secondary">
                      {article.category}
                    </strong>
                    <div className="th-fs-3">{article.title}</div>
                    <small className="text-secondary">{article.date}</small>
                    <br />
                    <Link className="btn btn-sm btn-dark float-start my-1" to={`/news/${article.id}`}>
                      Learn more
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-lg col-12">
            <div className="row">
              <div className="col-12">
                <img src={recentPost.image} className="img img-fluid w-100" />
              </div>
              <div className="col-12">
                <h3>{recentPost.title}</h3>
                <p>{recentPost.body}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedList;
