import React, { useState, useEffect, useRef } from "react";
import PriorityCase from "../modal/addPriority";
import $, { data } from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-dt";
const SimpleTable = ({ heads, rows, tableHeadContent, tableBodyContent }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [priorityCaseData, setPriorityCaseData] = useState(null);
  console.log("outside useEffect:", rows);
  const tableRef = useRef();
  const handleCloseModal = () => {
    setModalVisible(false);
    localStorage.setItem("Priority_Value", "");
  };
  useEffect(() => {
    $(function () {
      if (tableHeadContent === "accountsHeads") {
        $(tableRef.current).DataTable({
          destroy: true,
          ordering: false,
          data: rows,
          columns: heads,
        });
      } else if (tableHeadContent === "preparationsHeads") {
        $(tableRef.current).DataTable({
          destroy: true,
          ordering: false,
          data: rows,
          columns: heads,
          columnDefs: [
            {
              data: null,
              render: function (data, type, row, meta) {
                console.log(data);
                if (data <= 30) {
                  return "<button class='custom-button-class btn btn-danger px-5'></button>";
                } else if (data <= 70) {
                  return "<button class='custom-button-class btn btn-warning px-5'></button>";
                } else {
                  return "<button class='custom-button-class btn btn-success px-5'></button>";
                }
              },
              targets: -1,
            },
          ],
        });
      } else {
        const dataTables = $(tableRef.current).DataTable({
          destroy: true,
          ordering: false,
          data: rows,
          columns: heads,
          columnDefs: [
            {
              data: null,
              render: function (data, type, row, meta) {
                // Render two buttons in the last column
                return "<button class='custom-button1-class btn btn-success'>Edit</button><button class='custom-button2-class btn btn-danger'>Delete</button>";
              },
              targets: -1,
            },
            {
              targets: [0],
              visible: false,
            },
          ],
        });
        dataTables.on("click", "button.custom-button1-class", function (e) {
          console.log(e);
          let data1 = dataTables.row($(this).closest("tr")).data();
          //console.log(data1);
          if (data1 && data1.docID !== undefined) {
            handleEditPriorityCase(
              data1.docID,
              data1.name,
              data1.category,
              data1.status,
              data1.comments
            );
          } else {
            console.error("docID is undefined or null in the clicked row.");
          }
        });
        dataTables.on("click", "button.custom-button2-class", function (e) {
          let dataRow = dataTables.row($(this).closest("tr")).data();

          // Check if dataRow and docID are available
          if (dataRow && dataRow.docID !== undefined) {
            handleDeletePriorityCase(
              dataRow.docID,
              dataRow.name,
              dataRow.category,
              dataRow.status,
              dataRow.comments
            );
          } else {
            console.error("docID is undefined or null in the clicked row.");
          }
        });
      }

      // Destroy DataTable when the component unmounts
      return () => {
        $(tableRef.current).DataTable().destroy(true);
      };
    });
  }, [rows, heads]);
  const handleEditPriorityCase = (id, name, category, status, comments) => {
    setModalVisible(true);
    localStorage.setItem("Priority_Value", "Edit");
    const caseData = {
      docID: id,
      name: name,
      category: category,
      status: status,
      comments: comments,
      // ...other data
    };
    setPriorityCaseData(caseData);
    <PriorityCase
      showModal={isModalVisible}
      onClose={handleCloseModal}
      caseData={priorityCaseData}
    />;
  };
  const handleDeletePriorityCase = (id, name, category, status, comments) => {
    setModalVisible(true);
    localStorage.setItem("Priority_Value", "Delete");
    const caseData = {
      docID: id,
      name: name,
      category: category,
      status: status,
      comments: comments,
      // ...other data
    };
    setPriorityCaseData(caseData);
    <PriorityCase
      showModal={isModalVisible}
      onClose={handleCloseModal}
      caseData={priorityCaseData}
    />;
  };

  return (
    <>
      <table
        ref={tableRef}
        id="mySimpleTable"
        className="table table-sm table-bordered table-hover table-responsive text-center shadow-sm"
      ></table>
      <PriorityCase
        showModal={isModalVisible}
        onClose={handleCloseModal}
        caseData={priorityCaseData}
      />
    </>
  );
};

export default SimpleTable;
