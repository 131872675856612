import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { firestore } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";
const MyChartComponent = () => {
  const chartRef = useRef(null);
  const chartRefs = useRef({
    EARTHQUAKE: null,
    TYPHOON: null,
    FLOOD: null,
  });

  useEffect(() => {
    let myChart = chartRef.current;
    //users fetch Data and Chart Insert

    const fetchData = async () => {
      //fetch of data firebase
      try {
        const querySnapshotUser = await getDocs(collection(firestore, "user"));
        const querySnapshotAdmin = await getDocs(
          collection(firestore, "admin")
        );
        const usersData = querySnapshotUser.docs.map((doc) => doc.data());
        const adminsData = querySnapshotAdmin.docs.map((doc) => doc.data());
        //used to get the number of users
        const userTypes = {};
        usersData.forEach((user) => {
          const userType = user.user_type;
          userTypes[userType] = (userTypes[userType] || 0) + 1;
        });

        adminsData.forEach((admin) => {
          const userType = admin.user_type;
          userTypes[userType] = (userTypes[userType] || 0) + 1;
        });
        //labels
        const labels = Object.keys(userTypes);
        const data = Object.values(userTypes);
        const ctx = document.getElementById("myChart").getContext("2d");
        //if some charts is open destroy
        if (myChart) {
          myChart.destroy();
        }
        //charts
        myChart = new Chart(ctx, {
          type: "pie",
          data: {
            labels: labels,
            datasets: [
              {
                data: data,
                //background color
                backgroundColor: [
                  "rgba(54, 162, 235, 0.7)",
                  "rgba(255, 206, 86, 0.7)",
                  // Add more colors as needed
                ],
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              //title
              title: {
                display: true,
                text: "USER ACCOUNT REGISTERED",
              },
            },
          },
        });
      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };

    fetchData();
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render
  //checklist
  useEffect(() => {
    async function fetchDataFromFirestore() {
      const querySnapshot = await getDocs(
        collection(firestore, "Preparation_Checklist")
      );

      var categorizedData = {
        EARTHQUAKE: { 30: 0, 70: 0, 80: 0 },
        TYPHOON: { 30: 0, 70: 0, 80: 0 },
        FLOOD: { 30: 0, 70: 0, 80: 0 },
      };

      querySnapshot.forEach((doc) => {
        var fireData = doc.data();
        var category = fireData.category;
        var value = fireData.percentage;
        //counts
        if (value <= 30) {
          categorizedData[category]["30"]++;
        } else if (value > 30 && value <= 70) {
          categorizedData[category]["70"]++;
        } else if (value >= 80) {
          categorizedData[category]["80"]++;
        }
      });

      return categorizedData;
    }

    async function createPieChart(category, data) {
      if (chartRefs.current[category]) {
        chartRefs.current[category].destroy(); // Destroy the existing chart
      }

      const ctx = document.getElementById(category + "Chart").getContext("2d");
      const chart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["Urgent", "Unsatisfactory", "Satisfactory"],
          datasets: [
            {
              data: [data["30"], data["70"], data["80"]],
              backgroundColor: [
                "rgba(255, 0, 0, 0.8)",
                "rgba(245, 234, 39, 0.8)",
                "rgba(4, 186, 28, 0.8)",
                // Add more colors as needed
              ],
              borderColor: [
                "rgba(255, 0, 0, 0.8)",
                "rgba(245, 234, 39, 0.8)",
                "rgba(4, 186, 28, 0.8)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {},
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
            title: {
              display: true,
              text: category + " CHECKLIST",
            },
          },
        },
      });

      chartRefs.current[category] = chart; // Store the chart reference
    }
    //destroy
    const cleanupCharts = () => {
      const currentChartRefs = chartRefs.current; // Create a local variable for the chart references
      Object.keys(currentChartRefs).forEach(function (category) {
        if (currentChartRefs[category]) {
          currentChartRefs[category].destroy();
        }
      });
    };

    fetchDataFromFirestore().then(function (categorizedData) {
      // Create charts for each category
      Object.keys(categorizedData).forEach(function (category) {
        createPieChart(category, categorizedData[category]);
      });
    });

    return cleanupCharts;
  }, []);

  return (
    <div className="row">
      <div className="col-3">
        <canvas id="myChart" width="400" height="400"></canvas>
      </div>
      <div className="col-3">
        <canvas id="TYPHOONChart" width="400" height="200"></canvas>
      </div>
      <div className="col-3">
        <canvas id="EARTHQUAKEChart" width="400" height="200"></canvas>
      </div>
      <div className="col-3">
        <canvas id="FLOODChart" width="400" height="200"></canvas>
      </div>
    </div>
  );
};

export default MyChartComponent;
