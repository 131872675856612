import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import bannerImage from "../../assets/images/placeholder/banner-placeholder.jpg";

import poster from "../../assets/images/proj/p4.jpg";
import img1 from "../../assets/images/proj/p5.jpg";
import img2 from "../../assets/images/proj/p6.jpg";
import img3 from "../../assets/images/proj/p7.jpg";
import img4 from "../../assets/images/proj/p8.jpg";
import video1 from "../../assets/videos/earthquake.mp4";
import video2 from "../../assets/videos/cpr.mp4";
import video3 from "../../assets/videos/aid.mp4";
import video4 from "../../assets/videos/fire.mp4";

const NewsShow = () => {
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const altLabelImage = "banner-placeholder";
  useEffect(() => {
    sectionBorderRef.current.style.borderLeft = "7px solid #242424";
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });

  const { id } = useParams();
  const news = [
    {
      id: 1,
      imageName: img1,
      videoUrl: video1,
      title: "Earthquake Drill",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body:
        "DepEd and CHED officials agreed, saying students and teachers must be equipped with knowledge on mitigating and managing hazards and risks brought by natural disasters like earthquakes.",
    },
    {
      id: 2,
      imageName: img2,
      videoUrl: video2,
      title: "CPR Training",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body:
        "What to do once someone is not breathing? Learn how to do chest compressions as you do it on the beat of the song Staying alive.",
    },
    {
      id: 3,
      imageName: img3,
      videoUrl: video3,
      title: "First Aid Application Exercise",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body:
        "Injuries sustained while a disaster is occurring or has occurred? Learn how to apply first aid as this can definitely save a life.",
    },
    {
      id: 4,
      imageName: img4,
      videoUrl: video4,
      title: "Fire Prevention Month Exercises",
      createdAt: "Posted at  May 07, 2023 6:49 PM",
      body:
        "During the hottest month of the year, it is customary to exhibit drills that are useful to prevent the spread of fire ",
    },
  ];

  const image = {
    height: "80vh",
  };

  const customStyle = {
    marginTop: "100px",
  };

  const currentNews = news.find((item) => item.id === parseInt(id));

  return (
    <>
      <div className="container py-4" style={customStyle}>
        <div className="row m-0">
          {/* Section Title */}
          <div className="row justify-content-start mb-3">
            <div className="col-9 lh-sm" ref={sectionBorderRef}>
              <label className="th-fs-1 " ref={sectionTitleRef}>
                Events /
              </label>
              <label className="th-fs-1 th-fw-bold">{currentNews.title}</label>
              <br />
              <small className="text-secondary">{currentNews.createdAt}</small>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex justify-content-center my-5">
          <div className="row mx-0 gap-2">
            <div className="col-lg-8 col-md col-12 px-5">
              <div className="d-flex mb-4">
                {currentNews && <video src={currentNews.videoUrl} controls className="h-75 w-100" />}
              </div>
              <p className="th-fs-3 m-0 ">{currentNews.body}</p>
            </div>
            <div className="col-lg col-md col-12 col-md col-12 p-0">
              <img src={poster} className="img-fluid w-100" style={image} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsShow;