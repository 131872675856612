import React, { useState, useEffect } from "react";

import Announcement from "./pages/announcement/Announcement";
import Home from "./pages/home/Home";
import News from "./pages/news/News";
import NewsShow from "./pages/news/NewsShow";
import AnnouncementShow from "./pages/announcement/AnnouncementShow";
import Preparation from "./pages/preparation/Preparation";
import ExternalLayout from "./pages/layouts/ExternalLayout";
import InternalLayout from "./pages/layouts/InternalLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import User from "./pages/user/User";
import Profile from "./pages/profile/Profile";
import Evacuation from "./pages/evacuation/Evacuation";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import NotFound from "./pages/other/NotFound";
import AdminAnnouncement from "./pages/announcement/AdminAnnouncement";
import AdminNews from "./pages/news/AdminNews";
import AdminPreparation from "./pages/preparation/AdminPreparation";
import Case from "./pages/cases/Case";
import { auth } from "./firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { Routes, Route } from "react-router-dom";
const App = () => {
  const [currUserType, setCurrUserType] = useState(
    localStorage.getItem("logged_user_type")
  );
  const [isLogin, setLogin] = useState(false);
  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in
        setLogin(true);
      } else {
        // User is not logged in
        setLogin(false);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <Routes>
      {isLogin ? (
        <Route element={<ExternalLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsShow />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/announcement/:id" element={<AnnouncementShow />} />
          <Route path="/evacuation" element={<Evacuation />} />
          <Route path="/preparation" element={<Preparation />} />
          {currUserType != null && (
            <Route path="/profile" element={<Profile />} />
          )}
        </Route>
      ) : (
        <Route path="*" element={<Login />} />
      )}

      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />

      {currUserType === "admin" ? (
        <Route element={<InternalLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/accounts" element={<User />} />
          <Route path="/admin/announcements" element={<AdminAnnouncement />} />

          <Route path="/admin/news" element={<AdminNews />} />
          <Route path="/admin/preparations" element={<AdminPreparation />} />
          <Route path="/admin/cases" element={<Case />} />
        </Route>
      ) : (
        <Route path="*" element={<NotFound />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default App;
