import { Link } from "react-router-dom";
const NotFound = () => {
	return (
		<div
			className="modal d-block"
			id="staticBackdrop"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabindex="-1"
			aria-labelledby="staticBackdropLabel"
			aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h1
							className="modal-title th-fs-2 th-fw-bold"
							id="staticBackdropLabel">
							Error 404: Page Not Found
						</h1>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"></button>
					</div>
					<div className="modal-body">
						Oops! It seems like the page you're looking for could
						not be found. The requested resource could not be
						located on the server.
					</div>
					<div className="modal-footer">
						<Link
							type="button"
							className="btn th-btn-primary text-uppercase"
							to="/">
							go back
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
