import { useState, useRef, useEffect } from "react";
import postImage from "../../assets/images/placeholder/post-placeholder.jpg";
import { Link } from "react-router-dom";

const RecommendationList = ({ header, posts }) => {
  const [lists, setLists] = useState(posts);
  const sectionTitleRef = useRef(null);
  const sectionBorderRef = useRef(null);
  const imageRef = useRef(null);
  const cardimage = {
    height: "120px",
    width: "100%",
    objectFit: "cover",
  };
  useEffect(() => {
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });

  return (
    <>
      <div className="row justify-content-center mb-2">
        <div
          className="col d-flex justify-content-between align-items-center "
          ref={sectionBorderRef}
        >
          <label className="th-fs-3 m-0 fw-semibold" ref={sectionTitleRef}>
            {header}
          </label>
          <Link className="nav-link text-primary mx-2" to="/news">
            View here
          </Link>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12">
          {lists.map((list, index) => (
            <div className="row mb-3">
              <div className="col">
                <img
                  src={list.imageName}
                  alt={list.altImageLabel}
                  className="img-fluid w-100 rounded-4"
                  style={cardimage}
                />
              </div>
              <div className="col lh-sm">
                <strong className="text-secondary">{list.category}</strong>
                <label className="fs-6 m-0">{list.title}</label>
                <small>&#8226; {list.date_created}</small>
                <br />
                <Link className="btn btn-sm btn-dark mt-1" to={`/news/${index+1}`}>Learn more</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  useEffect(() => {
    sectionTitleRef.current.style.display = "flex";
    sectionTitleRef.current.style.alignItems = "center";
    sectionTitleRef.current.style.padding = "5px";
  });

  return (
    <>
      <div className="row justify-content-center mb-3">
        <div className="col" ref={sectionBorderRef}>
          <h4 ref={sectionTitleRef}>{header}</h4>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12">
          {lists.map((list) => (
            <div className="row mb-3">
              <div className="col-3">
                <img
                  src={postImage}
                  alt={list.altImageLabel}
                  className="img img-fluid w-100 h-100"
                />
              </div>
              <div className="col-9">
                <strong className="text-secondary">category</strong>
                <h5>accusantium doloremque</h5>
                <small>&#8226; {list.date_created}</small>
                <br />
                <button className="btn btn-sm btn-dark">read more</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RecommendationList;
