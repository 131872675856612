import { Link } from "react-router-dom";
import logoImage from "../../assets/images/icon/log.png";

const Footer = () => {
  return (
    <footer className="bg-dark position-relative">
      <div className="container">
        <div className="row px-4">
          <div className=" py-4" style={{ width: "300px" }}>
            <ul className="list-group mb-3 rounded-4">
              <li className="list-group-item">
                <div className="d-flex align-items-center gap-2">
                  <div className="col-4 d-flex justify-content-between align-items-center">
                    <img src={logoImage} className="img-fluid h-100 w-100" />
                  </div>
                  <div
                    className="col-7 text-wrap px-2"
                    style={{
                      overflowWrap: "break-word",
                      minWidth: "200px",
                    }}
                  >
                    <label className="th-fs-3 fw-semibold">E-handa</label>
                    <p>Barangay Disaster Preparedness System</p>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item  border-0 bg-transparent p-0">
                <h3 className="text-white">Contact Us</h3>
              </li>
              <li className="list-group-item  border-0 bg-transparent p-0">
                <p className="text-white">ehandatest@gmail.com</p>
              </li>
              <li className="list-group-item  border-0 bg-transparent p-0">
                <p className="text-white">+639123456789</p>
              </li>
            </ul>
          </div>
        </div>
        <hr className="p-0 my-0 border-secondary" />
        <div className="row jusitfy-content-between align-items-center py-4">
          <div className="col-6 col-md-6 col-sm-12">
            <label className="text-white">
              © 2023 E-handa. All rights reserved.
            </label>
          </div>
          <div className="col-6 col-md-6 col-sm-12">
            <div className="d-flex justify-content-end">
              <Link to="/home" className="text-white text-decoration-none px-3">
                Terms
              </Link>
              <Link to="/home" className="text-white text-decoration-none px-3">
                Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
