import React, { useState } from "react";
import { Link } from "react-router-dom";
import Styles from "../../assets/css/Admin.css";
import gridIcon from "../../assets/images/icon/gridIcon.png";
import userIcon from "../../assets/images/icon/userIcon.png";
import annnouncementIcon from "../../assets/images/icon/annnouncementIcon.png";
import eventsIcon from "../../assets/images/icon/eventsIcon.png";
import preparationsIcon from "../../assets/images/icon/preparationsIcon.png";
import casesIcon from "../../assets/images/icon/casesIcon.png";
import iconLogo from "../../assets/images/placeholder/logo-placeholder.png";
import PriorityCase from "../modal/addPriority";
import { signOut } from "firebase/auth";
import {auth} from "../../firebase"
const Admin = ({ title, content, table }) => {
  const [currUser, setCurrUser] = useState(localStorage.getItem("logged_user"));
  const customStyle = {
    marginTop: "6.5rem",
  };
  const iconStyle = {
    height: "25px",
    width: "25px",
  };
  const sidebarHeight = {
    height: "500px",
    overflow: "auto",
  };
  const sideNavItemLists = [
    {
      sideNavId: 1,
      redirectTo: "/admin/dashboard",
      name: "Dashboard",
      icon: gridIcon,
    },
    {
      sideNavId: 2,
      redirectTo: "/admin/accounts",
      name: "Accounts",
      icon: userIcon,
    },
   
    {
      sideNavId: 5,
      redirectTo: "/admin/preparations",
      name: "Preparations",
      icon: preparationsIcon,
    },
    {
      sideNavId: 6,
      redirectTo: "/admin/cases",
      name: "Cases",
      icon: casesIcon,
    },
  ];
  
  const handleSignout = async() => {
    await signOut(auth);
    localStorage.clear();
    window.location.href = window.location.origin + "/";
  };

  const [isModalVisible, setModalVisible] = useState(false);

  const handleAddPriorityCase = () => {
    setModalVisible(true);
    localStorage.setItem(
      "Priority_Value",
      "Add"
    );
  };
  
  const handleCloseModal = () => {
    setModalVisible(false);
    localStorage.setItem("Priority_Value", "");
  };

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap" style={customStyle}>
        <div
          className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2 px-sm-2 px-0 bg-dark position-fixed vh-100 "
          style={sidebarHeight}
        >
          <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white">
            <ul
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start p-3"
              id="menu"
            >
              {sideNavItemLists.map((sideNavItemList) => {
                return (
                  <li key={sideNavItemList.sideNavId} className="nav-item">
                    <Link
                      className="nav-link align-middle px-0 text-white"
                      to={sideNavItemList.redirectTo}
                    >
                      <img
                        src={sideNavItemList.icon}
                        className="img img-fluid"
                        style={iconStyle}
                      />
                      <span className="ms-1 d-none d-sm-inline">
                        {sideNavItemList.name}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>

            {currUser != null && (
              <>
                <hr className="border w-100" />
                <div className="dropdown pb-4">
                  <a
                    href="#"
                    className="d-flex align-items-center text-white text-decoration-none dropdown-toggle "
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={iconLogo}
                      width="30"
                      height="30"
                      className="rounded-circle"
                    />
                    <span className="d-none d-sm-inline mx-2">{currUser}</span>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-dark text-small shadow"
                    aria-labelledby="dropdownUser1"
                  >
                    {/* <li>
                      <Link className="dropdown-item" to="/profile">
                        Profile
                      </Link>
                    </li>
 */}
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={handleSignout}>
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-9 col-md-9 col-xl-10 p-5 offset-2 offset-sm-3 offset-md-3 offset-lg-3 offset-xl-2">
          <div className="col-12 th-bg-secondary shadow-sm mb-3 p-3 text-center">
            <h4 className="m-0 p-0">{title}</h4>
          </div>
          {title === "Priority Cases" ?
            (<div>
          <button className="btn btn-primary ml-auto mb-2" onClick={handleAddPriorityCase}>
            Add Priority
          </button>
          <PriorityCase showModal={isModalVisible} onClose={handleCloseModal} />
          </div>
            ) : (null)
          }
          <div className="row justify-content-centermb-3 m-0 p-0">
            <div
              className="col-12 m-0 p-0 overflow-auto"
              style={{ height: "50vh" }}
            >
              {content ?? table}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Admin;
