import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

const ForgotPasswordModal = ({ showModal, onClose }) => {
  const [emailForgot, setEmailForgot] = useState("");

  const handleEmailChange = (e) => {
    setEmailForgot(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    sendPasswordResetEmail(auth, emailForgot)
      .then(() => {
        alert("Password recovery email sent!");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(errorCode + ": " + errorMessage);
      });

    onClose();
  };

  return (
    <div
      className={`modal ${showModal ? "d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Forgot Password</h5>
            <button
              type="button"
              className="close btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mt-3">
            <form onSubmit={handleSubmit}>
              <div className="custom-field col-12 col-lg-12 mb-1">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control w-100 mb-3"
                  id="emailForgot"
                  placeholder="Enter your email"
                  value={emailForgot}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary mt-4">
                Send Email
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
