import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logoImage from "../../assets/images/icon/log.png";
import {auth} from "../../firebase"
import {signOut, onAuthStateChanged} from "firebase/auth";

const Header = ({ lists, isVisible }) => {
  const navListRef = useRef(null); //in-progress adding useRef hook
  const [activeItem, setActiveItem] = useState(null);
  const [navLists, setNavLists] = useState(lists);
  const [currUser, setCurrUser] = useState(localStorage.getItem("logged_user"));
  const [currType, setCurrType] = useState(localStorage.getItem("logged_user_type"));

  const handleItemClick = (event) => {
    if (activeItem) {
      activeItem.classList.remove("active");
    }
    event.target.classList.add("active");
    setActiveItem(event.target);
  };
  const stateChanged = async() =>{
    onAuthStateChanged(auth, user => {
      if(user){
        window.location.href = window.location.origin + "";
      }else{
        window.location.href = window.location.origin + "/";
      }
    });
  }
  const handleSignout = async() => {
    await signOut(auth);
    localStorage.clear();
    window.location.href = window.location.origin + "/";
  };
  const imageStyle = {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  return (
    <nav
      className="navbar  navbar-expand-lg navbar-light fixed-top bg-light px-5"
      style={{ zIndex: "1520" }}
    >
      <Link className="navbar-brand " to="/home">
        <img src={logoImage} className="img img-fluid" style={imageStyle} />
        <strong className="mx-2">E-Handa</strong>
      </Link>
      {isVisible == true && (
        <>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" ref={navListRef}>
              {navLists.length > 0
                ? navLists.map((navList) => {
                    return (
                      <li
                        key={navList.navId}
                        className="nav-item"
                        onClick={handleItemClick}
                      >
                        <Link className="nav-link" to={navList.redirectTo}>
                          {navList.name}
                        </Link>
                      </li>
                    );
                  })
                : ``}
            </ul>
            {localStorage.getItem("logged_user") ? (
              (localStorage.getItem("logged_user_type") === "admin") ? (
                  <ul className="navbar-nav  mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {localStorage.getItem("logged_user")}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/admin/dashboard">
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={handleSignout}>
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                ) : (
                  <ul className="navbar-nav  mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {localStorage.getItem("logged_user")}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={handleSignout}>
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                )
            ) : (
              <a className="text-secondary text-decoration-none" href="/login">
                Log in
              </a>
            )}
          </div>
        </>
      )}
    </nav>
  );
};

export default Header;
